.card-body--table {
	table {
		border-bottom-left-radius: $card-border-radius;
		border-bottom-right-radius: $card-border-radius;

		> *:last-child:not(.card-header) {
			tr:last-child {
				td {
					border-bottom-width: 0;

					&:first-child {
						border-bottom-left-radius: $card-border-radius;
					}

					&:last-child {
						border-bottom-right-radius: $card-border-radius;
					}
				}
			}
		}

		.cell-head {
			background-color: $card-cap-bg;
			height: auto;
			padding-top: 0;
		}

		tr > .cell {
			&:first-child {
				padding-left: $card-spacer-x;
			}

			&:last-child {
				padding-right: $card-spacer-x;
			}
		}
	}
}

.card.is-active {
	.card-body--table table .cell-head {
		background-color: $card-active-bg;
	}
}
