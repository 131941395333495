.accordion-item.accordion-item--terms {
	.accordion-button {
		&.collapsed {
			background: $gray-200;
		}

		&:after {
			display: none !important;
		}
	}
}

.terms-content-container {
	height: 300px;
	overflow-y: auto;
}

.card-header-link {
	svg {
		color: $gray-700;
	}

	&:hover {
		cursor: pointer;

		svg,
		.h4 {
			color: $primary;
		}
	}
}

.wysiwyg {
	a {
		color: $primary;
		@include underline(1, 0);
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}
}

#dr_globalWireInstructions {
	> *:not(.dr_thankYouElementPadding) {
		display: none;
	}
}

.text-link {
	@include underline(1, 0);
	cursor: pointer;
}

.text-link--hover {
	@include underline(0, 1);
	cursor: pointer;
}

.min-vh-content-100 {
	min-height: calc(100vh - #{$header-height});
}

.min-w-0 {
	min-width: 0;
}

.tooltip-text {
	display: inline-flex;
	gap: px2rem(6);
	align-items: center;
	font-weight: $font-weight-semibold;
	font-size: $font-size-sm;
	cursor: help;
	> span {
		text-decoration: underline;
	}

	&:hover {
		span {
			text-decoration: none;
		}
	}
}