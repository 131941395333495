.select__single-option {
	pointer-events: none;

	.select__indicators {
		display: none !important;
	}
}

// Temporary added solution until Component library 6.2.0 is released
.select__container.select-sm .select__control .select__placeholder,
.select__container.select-sm .select__control .select__single-value {
	font-size: 0.875rem;
}
