@use 'sass:math';

// **
// Base app container
// **
.app {
	position: relative;
	min-height: 100vh;

	.sidebar-container {
		position: absolute;
		left: 0;
		top: $header-height;
		bottom: 0;
		z-index: 200;
	}

	.content-container {
		margin-left: $sidebar-width;
		@include transitionCustom(margin-left);
		position: relative;
		height: calc(100vh - #{$header-height});
		overflow-y: auto;

		&:after {
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background-color: rgba($black, 0.6);
			content: '';
			z-index: 99;
			opacity: 0;
			@include transitionCustom(opacity);
			pointer-events: none;
		}
	}

	// Sidebar overly
	.sidebar-overlay {
		display: none;
		position: absolute;
		top: 0;
		left: $sidebar-width;
		right: 0;
		bottom: 0;
		@include gradient-x(rgba($black, 0.8), rgba($black, 0.75));
		z-index: 100;
		@include transitionCustom;
		opacity: 1;
	}

	// Collapsed
	&.is-collapsed {
		.header-app {
			padding-left: $sidebar-width-collapsed;
		}

		.logo-container {
			width: $sidebar-width-collapsed;
		}

		.sidebar-container {
			width: $sidebar-width-collapsed;

			.sidebar-content-container,
			.sidebar-padding-x {
				padding-left: $sidebar-collapsed-padding-x;
				padding-right: $sidebar-collapsed-padding-x;
			}

			.sidebar-padding-x {
				padding-left: $sidebar-collapsed-padding-x;
				padding-right: $sidebar-collapsed-padding-x;
			}

			.sidebar-margin-x {
				margin-left: $sidebar-collapsed-padding-x;
				margin-right: $sidebar-collapsed-padding-x;
			}

			.sidebar-menu > li {
				> a,
				> span.menu-link {
					justify-content: center;
					padding-right: $sidebar-collapsed-padding-x;

					&:before {
						right: 0;
						border-radius: 0;
					}
				}
			}

			.sidebar-collapse-hidden {
				width: 0;
				opacity: 0;
				visibility: hidden;
				margin-left: 0;
			}
		}

		.sidebar-footer .btn {
			transform: scaleX(-1);
		}
	}

	@include media-breakpoint-up(md) {
		&.is-collapsed {
			.content-container {
				margin-left: $sidebar-width-collapsed;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.content-container {
			margin-left: $sidebar-width-collapsed;
		}

		.header-app {
			padding-left: $sidebar-width-collapsed;
		}

		.sidebar-overlay {
			display: block;
		}

		&.is-collapsed {
			.sidebar-overlay {
				left: 0;
				pointer-events: none;
				opacity: 0;
			}
		}

		.sidebar-container {
			padding-top: 0;

			.sidebar-footer {
				padding-top: px2rem(4);
				padding-bottom: px2rem(4);
			}
		}

		.sidebar-menu > li {
			&.li-divider {
				margin-top: px2rem(10);
				margin-bottom: px2rem(10);
			}

			> a,
			> span.menu-link {
				padding-top: px2rem(7);
				padding-bottom: px2rem(7);
			}
		}
	}
}

// **
// Sidebar
// **
.sidebar-container {
	padding-top: $sidebar-padding-y;
	overflow-x: hidden;
	overflow-y: auto;
	background: $secondary;
	width: $sidebar-width;
	@include transitionCustom;

	.sidebar-content-container,
	.sidebar-padding-x {
		padding-left: $sidebar-padding-x;
		padding-right: $sidebar-padding-x;
		@include transitionCustom;
	}

	.sidebar-margin-x {
		margin-left: $sidebar-padding-x;
		margin-right: $sidebar-padding-x;
		@include transitionCustom;
	}

	.sidebar-collapse-hidden {
		@include transitionCustom(opacity);
	}

	.sidebar-footer {
		padding: px2rem(10) $sidebar-padding-x;
		background: darken($secondary, 4%);
		position: sticky;
		bottom: 0;

		.btn {
			padding: 0;
			@include transitionCustom;
			width: auto !important;
			min-width: 0 !important;
			opacity: 0.7;

			&:hover {
				border-color: transparent !important;
				opacity: 1;
			}

			&:focus,
			&:active {
				box-shadow: none !important;
			}
		}
	}
}

// **
// Header
// **
.header-app {
	background: $secondary;
	height: $header-height;
	display: flex;
	align-items: center;
	color: $white;
	padding-left: $sidebar-width;
	position: relative;

	.logo-container {
		display: flex;
		width: $sidebar-width;
		padding: 0 $sidebar-padding-x;
		position: absolute;
		flex-direction: column;
		justify-content: center;
		left: 0;
		top: 0;
		bottom: 0;
		@include transitionCustom(width);
		overflow: hidden;
		background: $secondary;
		z-index: 100;

		.text {
			@include transitionCustom;
			transform-origin: left center;
		}
	}

	.header-content {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	// User dropdown
	.header-dropdown {
		border-left: 1px solid rgba($white, 0.2);

		.dropdown-toggle-container {
			height: 100%;
			min-width: 200px;

			.btn {
				.btn--text {
					margin-right: spacer(2);
					text-transform: none;
					@include font(px2rem(15), 1.6);

					small {
						opacity: 0.75;
						font-size: px2rem(11);
					}
				}
			}
		}

		.dropdown-menu {
			min-width: 200px;
		}
	}
}

// **
// Menu
// **
.sidebar-menu {
	@include resetUl;

	a,
	&,
	span.menu-link {
		color: $gray-900;
	}

	> li {
		> a,
		> span.menu-link {
			display: flex;
			align-items: center;
			padding-top: px2rem(8);
			padding-bottom: px2rem(8);
			@include transitionCustom;
			overflow: hidden;
			padding-right: ($sidebar-padding-x + $sidebar-collapsed-padding-x);
			position: relative;
			color: rgba($white, 0.9);

			> * {
				position: relative;
			}

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: $sidebar-padding-x;
				content: '';
				@include transitionCustom;
				display: block;
				border-radius: 0 100px 100px 0;
			}

			&:hover {
				cursor: pointer;
			}

			&:hover,
			&.active {
				&:before {
					background-color: rgba($white, 0.15);
				}

				color: $white;
			}

			&.active {
				font-weight: $font-weight-medium;
			}

			.icon {
				align-self: flex-start;
			}

			.caption {
				margin-left: px2rem(10);
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		// Divider
		&.li-divider {
			height: 1px;
			background: rgba($white, 0.15);
			margin-top: px2rem(14);
			margin-bottom: px2rem(14);

			+ .li-divider,
			&:last-child {
				display: none !important;
			}
		}
	}
}

.sidebar-section {
	height: 100%;
	display: flex;
	flex-direction: column;

	.sidebar-mainMenu {
		flex-grow: 1;
	}
}

// **
// Container
// **
.container-fluid {
	padding: $container-fluid-indent-y $container-fluid-indent-x;

	> .table-data:first-child {
		margin-top: -(math.div($container-fluid-indent-y, 2));
	}
}

.container-orderReview {
	@include maxWidth(1420px);
}

.fullHeight-layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	> .container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.verticalCenter-layout {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.min-h-100 {
	min-height: 100%;
}
