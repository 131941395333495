.modal-backdrop.show,
.modal.show {
	&[aria-hidden='true'] {
		opacity: 0 !important;
	}
}

body.modal-open .modal {
	@include media-breakpoint-up(sm) {
		padding-left: 15px;
	}
}

@include media-breakpoint-down(md) {
	.modal-lg {
		max-width: 100%;
	}
}

.modal--comparativeFeatures .modal-dialog {
	max-width: 1360px;
}
