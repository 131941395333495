// Spacer col
.col-hr {
	padding: 0 !important;
}

// Multi row / row sizes
@each $size, $spacer in $grid-gutters {
	$class: '-#{$size}';
	@if $size == '' {
		$class: '';
	}
	.row#{$class} {
		@if $size != '' {
			margin-left: -$spacer;
			margin-right: -$spacer;

			> [class^='col'],
			> [class*=' col'] {
				padding-left: $spacer;
				padding-right: $spacer;
			}
		}

		&.multi-row {
			> [class^='col'],
			> [class*=' col'] {
				padding-top: $spacer;
				padding-bottom: $spacer;
			}
		}
	}
}
