$fileDropzone-active: $primary !default;
$fileDropzone-bg: $gray-200 !default;
$fileDropzone-border-color: $gray-400 !default;
$fileDropzone-border-color-active: $fileDropzone-active !default;
$fileDropzone-border-width: 2px !default;
$fileDropzone-border-radius: $border-radius !default;
$fileDropzone-padding-x: px2rem(16) !default;
$fileDropzone-padding-y: px2rem(16) !default;

.fileDropzone {
	background-color: $fileDropzone-bg;
	border: $fileDropzone-border-width dashed $fileDropzone-border-color;
	border-radius: $fileDropzone-border-radius;
	padding: $fileDropzone-padding-y $fileDropzone-padding-x;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
	min-height: 100px;
	@include transitionCustom;

	.fileDropzone-input {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		pointer-events: none;
	}

	.fileDropzone-icon {
		position: absolute;
		font-size: px2rem(100);
		transform: rotate(14deg) translate(10%, 22%);
		left: 0;
		bottom: 0;
		opacity: 0.1;
		pointer-events: none;
	}

	.fileDropzone-placeholder {
		color: $gray-800;
		font-size: px2rem(18);
	}

	.fileDropzone-value {
		color: $fileDropzone-active;
		font-size: px2rem(18);
		font-weight: $font-weight-bold;
	}

	&:hover,
	&.is-active {
		border-color: $fileDropzone-border-color-active;

		.fileDropzone-placeholder {
			color: $fileDropzone-active;
		}
	}
}
