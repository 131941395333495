/**
 Detail of entity table
 */
.table-detail-data {
	a {
		color: $primary;
		@include underline(1, 0);
	}

	th {
		font-weight: $font-weight-medium;
	}

	td {
		font-weight: $font-weight-normal;
	}

	> :not(caption) > tr.highlighted > * {
		background-color: mix($secondary, $white, 8%);
	}

	&.table-noLastBorder {
		tbody tr:last-child > * {
			border-width: 0;
		}
	}

	&.is-simple {
		th,
		td {
			vertical-align: top;
			padding-top: px2rem(6);
			padding-bottom: px2rem(6);
		}

		&:not(.content-width) td {
			width: 60%;
		}

		tbody tr > * {
			border-width: 0 !important;
		}
	}

	&.is-airy {
		th,
		td {
			height: $table-cell-min-height;
			vertical-align: middle;
		}

		&:not(.content-width) td {
			width: 60%;
		}

		tbody tr {
			> :first-child {
				padding-left: $card-spacer-x;
			}

			> :last-child {
				padding-right: $card-spacer-x;
			}

			&:hover {
				background-color: $table-hover-bg;
			}
		}
	}
}

.card-body > .table-detail-data:only-child {
	tbody tr:last-child {
		td,
		th {
			border-bottom-width: 0;
		}

		th {
			border-bottom-left-radius: $border-radius-lg;
		}

		td {
			border-bottom-right-radius: $border-radius-lg;
		}
	}
}

.table {
	.cell-sticky-end .btn-composition {
		justify-content: flex-end;
	}

	&.table-fixed {
		table-layout: fixed;
	}

	&.table-transparent {
		background: transparent;

		> :not(caption) > * > * {
			background: transparent;
		}

		thead tr {
			&,
			th {
				background-color: transparent !important;
			}
		}

		tr {
			background: transparent;
		}
	}

	&.table-autoHeight {
		.cell {
			height: auto;
		}
	}

	&.table-head-autoHeight {
		.table-head .cell {
			height: auto;
		}
	}

	&.table--sm {
		.cell.cell-head {
			font-size: px2rem(13);
		}

		.cell.cell-body {
			font-size: px2rem(14);
		}
	}

	&.table-refunds {
		.cell.cell-foot {
			font-weight: normal;
		}
	}

	tr.disabled {
		color: $gray-600 !important;

		td {
			background-color: $table-head-bg;
			cursor: auto;
		}
	}

	tr.table-primary {
		--bs-table-bg: #{rgba($primary, 0.05)};
		--bs-table-hover-bg: #{rgba($primary, 0.08)};
	}
}

.table-hover {
	tbody {
		tr {
			&.disabled > * {
				color: $gray-600 !important;
			}
		}
	}
}

.table-scrollableVertically {
	.table-content {
		overflow-y: auto;
		max-height: $table-scrollable-max-height;

		table thead,
		table tfoot {
			position: sticky;
			z-index: 10;
			box-shadow: 0 0 20px rgba(#000, 0.08);
		}

		table thead {
			inset-block-start: 0;
		}

		table tfoot {
			inset-block-end: 0;

			.cell.cell-foot {
				border-top-width: $table-border-width;
			}
		}
	}
}

/**
 Data table
 */
.table-content.table-data {
	.table {
		.cell-head {
			white-space: nowrap;
		}
	}

	// License key
	.license-key {
		display: flex;
		align-items: center;

		> span {
			/*white-space:   nowrap;
			overflow:      hidden;
			max-width:     34ch;
			text-overflow: ellipsis;
			display:       block;*/
		}
	}
}

/**
 * Custom table
 */
.table-custom {
	.cell-head {
		background-color: transparent;
		height: auto;
	}

	&.table-borderless {
		.cell {
			height: auto;
		}

		tr {
			> .cell:first-child {
				padding-left: 0;
			}

			> .cell:last-child {
				padding-right: 0;
			}
		}
	}
}

/**
 * Order products
 */
.table-orderProducts {
	background-color: transparent;
	border-collapse: separate;
	border-spacing: 0;

	.cell {
		height: auto;
	}

	thead tr {
		&,
		th {
			background-color: transparent !important;
		}

		.ico-remove {
			color: $dark;
		}
	}

	tbody {
		tr {
			background-color: transparent;
		}

		tr.divider {
			td {
				height: px2rem(12);
				padding: 0 !important;
				background-color: transparent;
			}
		}

		tr.row-product,
		tr.row-product-detail {
			> td {
				border-bottom: 1px solid $gray-500;
			}

			> td:first-child {
				border-left: 1px solid $gray-500;
			}

			> td:last-child {
				border-right: 1px solid $gray-500;
			}
		}

		tr.row-product {
			> td {
				border-top: 1px solid $gray-500;
				background-color: $white;
			}

			> td:last-child {
				border-top-right-radius: $border-radius-lg;
			}

			.ico-detail-toggle .btn--icon {
				@include transitionCustom;
			}

			.ico-remove {
				color: $danger;
			}

			.cell {
				height: 76px;
			}

			&.is-open {
				.ico-detail-toggle .btn--icon {
					transform: rotate(180deg);
				}
			}

			&:not(.is-open) {
				> td:first-child {
					border-bottom-left-radius: $border-radius-lg;
				}

				> td:last-child {
					border-bottom-right-radius: $border-radius-lg;
				}
			}
		}

		tr.row-product-detail {
			border: 1px solid $gray-500;
			border-top-width: 0;

			> td {
				background-color: $gray-100;
				padding-top: 0;
				padding-bottom: 0;
			}

			> td:first-child {
				border-bottom-left-radius: $border-radius-lg;
			}

			> td:last-child {
				border-bottom-right-radius: $border-radius-lg;
			}
		}
	}
}

.modal .table {
	.table-head,
	&.table-hover .table-body tr:not(:hover):not(.table-active),
	&:not(.table-hover) .table-body tr:not(.table-active) {
		.cell-head,
		.cell-body {
			background-color: $modal-content-bg;
		}
	}
}

.table-currentLicenseInfo {
	tbody td {
		padding-left: 12px;
	}
}

.comparative-table {
	text-align: center;
	margin-top: px2rem(10);

	&,
	tr {
		background-color: transparent;
	}

	thead tr {
		background-color: $gray-100;

		th {
			border-color: $gray-200 !important;
			padding: 0;
		}

		.th-container {
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-between;
			padding: px2rem(12);

			.badge {
				white-space: nowrap;
				position: absolute;
				left: 50%;
				top: 0;
				transform: translate(-50%, -50%);
			}
		}
	}

	tbody {
		td {
			border-color: $gray-200 !important;
			height: 56px;
			vertical-align: middle;
		}
	}

	tfoot {
		small {
			font-size: px2rem(12);
		}

		.price {
			font-size: px2rem(24);
		}

		td {
			border-color: $gray-200 !important;
		}

		tr:last-child td {
			border-width: 0 !important;
		}
	}

	td.is-separator {
		width: 40px;
	}

	.td-tooltip {
		padding: px2rem(14);

		.text-tooltip {
			font-weight: $font-weight-bold;
			display: inline-block;
			border-bottom: 2px dashed $gray-400;
			white-space: nowrap;

			&:hover {
				border-color: $primary;
			}
		}
	}

	td.is-positive {
		background-color: rgba(225, 228, 229, 0.25);
		color: #8c8c8c;

		&:not(.is-new) svg {
		}

		&.is-new {
			color: $success;
			background-color: rgba($success, 0.1);
		}
	}

	td.is-negative {
		color: $dark;

		svg {
			opacity: 0.5;
		}
	}

	td.is-fixed-width {
		width: 150px;
	}
}

.table-creditCardPreview {
	tbody tr td,
	thead tr th {
		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	thead {
		th {
			border-bottom-width: 0 !important;
			text-transform: uppercase;
			@include font($font-size-sm);
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	tr.creditCard {
		td {
			vertical-align: middle;
		}
	}

	td.thumb {
		padding: 0;
		width: 40px;
	}

	td.actions {
		padding-right: 0;
	}

	tr.is-expanded,
	tr:last-child {
		td {
			border-bottom-width: 0;
		}
	}

	tr.creditCardDetail {
		@include font($font-size-md, $line-height-md);

		.icon-container {
			padding-left: px2rem(26);
			position: relative;

			> .ico {
				position: absolute;
				left: 0;
				top: ($font-size-md * $line-height-md * 0.5);
				transform: translateY(-50%);
				opacity: 0.5;
			}
		}
	}
}
