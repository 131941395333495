@mixin resetUl {
	margin: 0;
	padding: 0;
	display: block;
	li {
		display: block;
		list-style-type: none;
	}
}

ul,
ol {
	&.reset {
		@include resetUl;
	}
}

.valid-feedback {
	display: none !important;
}

.tsqd-open-btn-container {
	transform: translate(-50%, 50%);
}