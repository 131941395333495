/**
 * Check, error icon
 */
@mixin iconCircleAnimated($color, $fillAnimation, $size: 70px) {
	box-shadow: inset 0 0 0 $color;
	width: $size;
	height: $size;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: $white;
	stroke-miterlimit: 10;
	margin: 0 auto;
	animation:
		$fillAnimation 0.4s ease-in-out 0.4s forwards,
		icon--circleAnimated__scale 0.3s ease-in-out 0.9s both;
	animation-delay: 0.6s;

	&__circle {
		stroke: $color;
		stroke-dasharray: 216;
		stroke-dashoffset: 216;
		stroke-width: 2;
		stroke-miterlimit: 10;
		fill: none;
		animation: icon--checkCircleAnimated__stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}
}

/**
 * Check icon
 */
.icon--checkCircleAnimated {
	@include iconCircleAnimated($success, icon--checkCircleAnimated__fill);

	&__check {
		transform-origin: 50% 50%;
		stroke-dasharray: 98;
		stroke-dashoffset: 98;
		animation: icon--checkCircleAnimated__stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	}
}

@keyframes icon--checkCircleAnimated__stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes icon--circleAnimated__scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes icon--checkCircleAnimated__fill {
	100% {
		box-shadow: inset 0 0 0 80px $success;
	}
}

/**
 * Error/Warning icon
 */
@mixin iconCrossCircle($name, $color) {
	.icon--#{$name}CircleAnimated {
		@include iconCircleAnimated($color, icon--#{$name}CircleAnimated__fill);

		&__line {
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			stroke-miterlimit: 10;
			stroke-width: 2;
			stroke-linecap: round;
			fill: none;
			stroke: $white;
			animation: icon--#{$name}CircleAnimated__dash 0.9s 0.35s ease-in-out forwards;
		}
	}

	.icon--#{$name}CircleAnimated {
		@include iconCircleAnimated($color, icon--#{$name}CircleAnimated__fill);

		&__line {
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			stroke-miterlimit: 10;
			stroke-width: 2;
			stroke-linecap: round;
			fill: none;
			stroke: $white;
			animation: icon--#{$name}CircleAnimated__dash 0.9s 0.35s ease-in-out forwards;
		}
	}

	@keyframes icon--#{$name}CircleAnimated__fill {
		100% {
			box-shadow: inset 0 0 0 80px $color;
		}
	}

	@keyframes icon--#{$name}CircleAnimated__dash {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}
}

@include iconCrossCircle('error', $danger);
@include iconCrossCircle('warning', $warning);
