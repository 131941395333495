@use 'sass:math';

// **
// Filter quick actions
// **
.component__quick-actions {
	display: flex;

	.caption {
		margin-right: spacer(3);
		flex-shrink: 0;
		min-height: $btn-height-xs;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.actions {
		flex-grow: 1;
	}
}

// **
// Copy to clipboard component
// **
.component__copy2clipboard {
	display: flex;
	align-items: center;

	> div {
		min-width: 0;
	}

	> button {
		flex-shrink: 0;
	}
}
.component__copy2clipboard-container {
	display: flex;
	align-items: center;
}

// **
// Select entity (user, partner, ...) boxes - first step of a order
// **
.component__select-entity-boxes {
	.col-card {
		width: math.div(100%, 3);
		max-width: 460px;
		min-width: 400px;
	}

	.card {
		height: 100%;
	}

	.card-body {
		min-height: 270px;

		.note {
			padding-left: spacer(2);
			padding-right: spacer(2);
			text-align: center;
			letter-spacing: 0.2px;
		}

		.note-bottom {
			color: $primary;
			text-align: right;
			font-weight: $font-weight-semibold;

			&.note-muted {
				color: $text-muted;
			}

			> p {
				margin: 0;
			}
		}

		.state-select {
			height: 100%;
			display: block;

			> div {
				display: flex;
				flex-direction: column;
			}

			.note {
				height: 46%;
				justify-content: flex-end;
				padding-bottom: spacer(1);
			}

			.actions-container {
				height: 54%;
				justify-content: space-between;

				.actions {
					text-align: center;
				}
			}
		}

		// Result
		.state-result {
			display: flex;
			flex-direction: column;
			height: 100%;

			.content {
				flex-grow: 1;
			}
		}
	}

	// Disable
	.card.is-disabled .card-body,
	.card .card-body.is-disabled {
		.note-bottom,
		.note:not(.note-disabled) {
			color: $disabled-color;
		}
	}

	@include media-breakpoint-down(md) {
		.col-card {
			width: 100%;
			min-width: 0;
		}
	}
}

/**
 * Search box
 */
.component__search-box {
	&.is-inline > .form-control {
		width: px2rem(260);

		&.is-empty {
			width: (px2rem(260) + $btn-height-sm - px2rem($btn-border-width));
		}
	}
}

/**
 * Search COMBO box
 */
.component__search-combo-box {
	.btn.has-icon.only-icon {
		font-size: inherit;
	}
}

/**
 * Select button
 */
.select-button {
	.chevron {
		font-size: 0.875rem;
		color: #514988;
	}
}

// **
// Filter
// **
.component__orderReview-table {
	background-color: $gray-300;
	position: relative;
	z-index: 10;
}

.component__detail-headline {
	background-color: $gray-400;
	border-bottom: 1px solid $gray-500;
}

.component__pricing-message-container,
.component__promotion-message-container {
	@include media-breakpoint-up(md) {
		columns: 2;
	}
	@include media-breakpoint-up(lg) {
		columns: 3;
	}
}

.component__checkout-iframe {
	width: 100%;
	height: max(80vh, 500px);
	border: none;
}

.component__asyncContainer {
	position: absolute;
}

.component__showMoreLine {
	text-align: center;
	position: relative;

	&:before {
		content: '';
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 2px;
		background: $gray-300;
		background: linear-gradient(90deg, transparent 0%, $gray-300 10%, $gray-300 90%, transparent 100%) !important;
		pointer-events: none;
		display: block;
		position: absolute;
	}

	.btn {
		background-color: $gray-100 !important;
		position: relative;
	}
}

.component__line {
	height: 2px;
	background-color: $gray-400;
}

.component__priceCalculator-table {
	td,
	th {
		vertical-align: middle;
	}
}

.component__current-language {
	display: flex;
	align-items: center;

	img {
		width: 20px;
		margin-right: spacer(2);
		border-radius: 2px;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
	}
}

.component__language-option-container {
	display: flex;
	align-items: center;

	.image {
		margin-right: px2rem(6);

		img {
			border-radius: 2px;
			width: 20px;
			box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
		}
	}
}

.component__widget {
	$borderRadius: 0 $border-radius-lg $border-radius-lg $border-radius-lg;

	.placeholder {
		background: #d9eafe;
	}

	.widget-item {
		margin-bottom: $grid-gutter-spacer;
	}

	.widget-row {
		$padding: $grid-gutter-spacer;

		display: block;
		margin-bottom: $padding;

		+ .widget-row {
			margin-top: $padding * 2;
		}
	}

	.widget-draggable {
		user-select: none;

		&.is-dragging {
			&.widget-item {
				.card,
				.card-header {
					border-color: $primary;
				}
			}
		}

		&:hover {
			&.widget-item {
				.card,
				.card-header {
					border-color: $gray-700;
				}
			}
		}
	}

	.column-container {
		background: $gray-100;
		border: 2px solid $gray-400;
		border-radius: $borderRadius;
		padding: $grid-gutter-spacer;
		width: 100%;

		&.is-active {
			border-color: $primary;
		}
	}
}
