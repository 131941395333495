$stepper-number-size: $btn-height;
$stepper-separator-width: 40px;
$stepper-margin-x: 16px;
$stepper-margin-y: 6px;

.component__stepper {
	background-color: $gray-300;
	text-align: center;

	ul {
		display: inline-flex;
		flex-wrap: wrap;
		margin: 0;
		list-style-type: none;
	}

	li {
		@include font(px2rem(18), px2rem(26), $font-weight-semibold);
		position: relative;
		margin: $stepper-margin-y 0 $stepper-margin-y $stepper-margin-x;
		color: $gray-900;

		&:not(:last-child) {
			padding-right: ($stepper-margin-x + $stepper-separator-width);

			&:after {
				display: block;
				position: absolute;
				right: 0;
				width: $stepper-separator-width;
				top: 50%;
				content: '';
				background: $gray-600;
				height: 1px;
			}
		}

		.stepper-item {
			display: flex;
			align-items: center;

			.iterator {
				width: $stepper-number-size;
				height: $stepper-number-size;
				display: block;
				text-align: center;
				@include font($btn-font-size, $stepper-number-size, $btn-font-weight);
				background-color: $gray-600;
				color: $white;
				border-radius: 100%;
				margin-right: px2rem(12);
			}
		}

		.stepper-link {
			color: currentColor;
			background: transparent;
			border-width: 0;
			margin: 0;
			padding: 0;
			font: inherit;
			display: block;

			&:hover,
			&:focus {
				border-width: 0;
				outline: 0;
			}

			&:hover {
				color: $body-color;

				.stepper-item .iterator {
					background-color: $brand;
				}
			}
		}

		&.is-active {
			color: $body-color;

			.stepper-item .iterator {
				background-color: $primary;
			}
		}
	}
}

.component__stepperVertical-headline {
	display: flex;
	align-items: center;

	.iterator {
		width: $stepper-number-size;
		height: $stepper-number-size;
		display: block;
		text-align: center;
		@include font($btn-font-size, $stepper-number-size, $btn-font-weight);
		background-color: $gray-600;
		color: $white;
		border-radius: 100%;
	}

	&.is-active {
		.iterator {
			background-color: $primary;
		}

		&.is-highlighted .iterator {
			background-color: $brand;
		}
	}
}
