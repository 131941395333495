@use 'sass:math';

$salesForce: #0f9bd7;
$badge-border-radius: px2rem(4);
$badge-padding-y: px2rem(6);
$badge-padding-x: px2rem(9);
$badge-pill-padding-y: px2rem(4);
$badge-pill-padding-x: px2rem(10);
$badge-font-size: px2rem(12);
$badge-font-weight: 600;
$table-scrollable-max-height: px2rem(320);

// Accordion
$accordion-bg: #fff;

// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 980px,
	lg: 1292px,
	xl: 1500px,
) !default;

// Grid containers
$container-max-widths: (
	sm: 540px,
	md: 940px,
	lg: 1260px,
	xl: 1540px,
) !default;

// Spacers
$spacer: 1rem;
$custom-spacers: (
	5: (
		$spacer * 1.5,
	),
	6: (
		$spacer * 2,
	),
	7: (
		$spacer * 2.5,
	),
	8: (
		$spacer * 3,
	),
	9: (
		$spacer * 4,
	),
	10: (
		$spacer * 5.5,
	),
	11: (
		$spacer * 7,
	),
	12: (
		$spacer * 8.5,
	),
	13: (
		$spacer * 10,
	),
);

// App skeleton
$header-height: 72px;
$sidebar-width: 280px;
$sidebar-width-collapsed: 82px;
$sidebar-padding-x: px2rem(28);
$sidebar-collapsed-padding-x: px2rem(16);
$sidebar-padding-y: px2rem(28);

// Container
$container-fluid-indent-x: px2rem(30);
$container-fluid-indent-y: px2rem(20);

// Grid spacer / gutters
$grid-gutter-width: 30px;
$grid-gutter-spacer: math.div($grid-gutter-width, 2);
$grid-gutter-width-md: 22px;
$grid-gutter-spacer-md: math.div($grid-gutter-width-md, 2);
$grid-gutter-width-sm: 14px;
$grid-gutter-spacer-sm: math.div($grid-gutter-width-sm, 2);
$grid-gutters: (
	'': $grid-gutter-spacer,
	md: $grid-gutter-spacer-md,
	sm: $grid-gutter-spacer-sm,
);

// Margins
$paragraph-margin-bottom: 0.75rem;

// Tabs
$nav-link-padding-y: px2rem(14);
$nav-link-padding-x: px2rem(15);
$nav-tabs-link-min-height: 0;
$nav-tabs-font-size: px2rem(17);
