// Copy button with icon
.btn.btn-copy.has-icon.only-icon {
	@include font(px2rem(12));
	width: px2rem(22);
	height: px2rem(22);
	min-height: px2rem(22);
	padding: 0;
	line-height: px2rem(22);
}

// Button with badge inside
.btn {
	.btn--text {
		.badge {
			min-width: px2rem(20);
			height: px2rem(20);
			padding: 0 px2rem(2);
			text-align: center;
			border-radius: 50%;
			@include font(px2rem(12), px2rem(20), $font-weight-bold);
		}
	}
}

.action-link {
	margin: 0;
	padding: 0;
	display: inline;
	background: 0;
	outline: 0;
	border-width: 0;
	text-decoration: underline;
	color: currentColor;

	&:hover {
		text-decoration: none;
	}
}
