.custom-slider {
	.slider-viewport {
		overflow: hidden;
		width: 100%;
	}

	.slider-container {
		display: flex;
	}

	.slide {
		position: relative;
		min-width: 100%;
	}
}
