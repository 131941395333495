// **
// Filter
// **
.section__filter {
	background-color: $gray-100;
	position: relative;
	z-index: 12;
	padding-top: px2rem(14);
	padding-bottom: px2rem(14);
	border-bottom: 1px solid $gray-300;

	.form-group {
		margin-bottom: 0;
	}

	.component__quick-actions {
		border-bottom: 1px solid $gray-300;
		padding-bottom: spacer(2);
		margin-bottom: spacer(2);
	}

	[class^='col'],
	[class*=' col'] {
		&.text-end > .btn-composition {
			display: inline-flex;
		}
	}
}

/**
 * Content with max width
 */
.section__content {
	@include maxWidth(700px);
}

.section__stepper-vertical {
	@include maxWidth(1000px);
}

// **
// Full screen loading
// **
.section__fullscreen-loading {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: $gray-200;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

// **
// Login form
// **
.section__security-login-form {
	// Set to the center of a page
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $gray-900;
	padding: px2rem(40) spacer(3);

	.env-label {
		position: fixed;
		right: spacer(3);
		top: spacer(3);
	}

	// Logo
	.logo-container {
		display: flex;
		justify-content: center;
		.logo {
			height: 38px;
			width: auto;
		}
	}

	.content-container {
		width: 100%;
		background: $white;
		max-width: 500px;
		min-height: 400px;
		color: $body-color;
		border-radius: $border-radius-lg;
		padding: spacer(7) spacer(6) spacer(4);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	// Form
	form {
		.btn {
			min-height: $input-height-lg;
		}
	}

	// Remove margin and reset border radius for form groups
	.form-group {
		.form-label {
			color: white;
		}

		input {
			border-radius: $input-border-radius;
		}
	}

	.fields {
		.form-group {
			margin-bottom: 0;

			&:not(:first-child) input {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}

			&:not(:last-child) input {
				border-bottom-width: 0;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		+ .form-group {
			margin-top: spacer(3);
		}
	}
}

.section__orderUpdate {
	.component__orderReview-table {
		background-color: $gray-100;
	}
}

.section__help {
	.video-list {
		li {
			position: relative;
			padding: 3px 0 2px 18px;
			cursor: pointer;
			@include underline(1, 0);

			&:before {
				content: '►';
				font-family: Arial;
				font-size: 80%;
				position: absolute;
				top: px2rem(6);
				left: 0;
			}

			&.is-active {
				color: $primary;
				text-decoration: none;
				font-weight: $font-weight-semibold;
			}
		}
	}

	.player-wrapper {
		position: relative;
		padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
	}

	.react-player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.component__help-bg {
		background-repeat: no-repeat;
		background-position: 0 0;
		background-image: url('/assets/image/help/guide-bg.png');

		&[data-step='home'] {
			background-image: url('/assets/image/help/home-bg.png');
		}
	}

	.component__help[data-step='home'] {
		.card-body {
			background: $card-cap-bg;
		}
	}

	.btn-back {
		padding: 0 !important;
		background: transparent !important;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: $font-weight-bold;
		border-width: 0 !important;

		.btn--text {
			text-decoration: none;
		}

		.btn--icon + .btn--text {
			margin-left: px2rem(16) !important;
		}

		&:hover {
			color: $primary !important;
		}
	}

	.btn-guide-hidden:disabled {
		opacity: 0;
		background: transparent;
		border-color: transparent;
	}
}